<template>
  <!-- 默认的消息: 回答读取 content 数据，提问读取 question 数据 -->
  <div
    class="chat-robot-box text-message"
    v-if="message.content || message.question"
  >
    <em
      v-show="message.style"
      class="icon-dot"
      style="float: left;top: 0.05rem;"
    ></em>
    <div
      class="text-message_content"
      :style="message.style && 'font-weight: bold;'"
      v-html="message.content || message.question"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'BubbleTxt',
  props: {
    message: [Object, String]
  }
}
</script>
